import React from "react";

import DataCard from "components/DataCard/DataCard";
import request, { gql } from "graphql-request";
import { Network } from "hooks/query/useNetworks";
import { useQueries } from "react-query";

interface IOverallUniqueUsersProps {
  networks: Network[];
}

async function getUniqueUsersData(network: Network) {
  const uniqueUsersData = await request(
    network.v2GraphUrl,
    gql`
      query {
        uniqueWalletCount(id: "0") {
          count
        }
      }
    `
  );

  return uniqueUsersData.uniqueWalletCount.count as string;
}

const OverallUniqueUsers: React.FC<IOverallUniqueUsersProps> = ({
  networks,
}) => {
  const uniqueUsersQueries = useQueries(
    networks?.map((network) => {
      return {
        queryKey: [`uniqueUsersOn${network.name}`, network.chainId],
        queryFn: () => getUniqueUsersData(network),
      };
    }) ?? []
  );

  const isLoading = uniqueUsersQueries.some(
    (uniqueUserQuery) => uniqueUserQuery.status === "loading"
  );

  const uniqueUsersCount = !isLoading
    ? uniqueUsersQueries
        .reduce(
          (acc, currentValue) => acc + Number.parseInt(currentValue.data + ""),
          0
        )
        .toString()
    : undefined;

  return <DataCard title="Unique Users" body={uniqueUsersCount || "..."} />;
};

export default OverallUniqueUsers;
