import { Token } from "hooks/query/useTokens";
import getNetworkTokens from "utils/getNetworkTokens";

export interface INetworkConfig {
  liquidityPoolContractAddress: string;
  liquidityProvidersContractAddress: string;
  subgraphEndpoint: string;
  networkName: string;
  coinGeckoId?: string;
  tokens: INetworkTokenConfig[];
  networkId: number;
  rpcUrl: string;
}

export interface INetworkTokenConfig {
  symbol: string;
  contractAddress: string;
  decimals: number;
  coinGeckoId: string;
}

export function getTokenIdToContractAddressMapping(
  uniqueTokenAddresses: string[],
  networkConfig: INetworkConfig,
  tokens: Token[]
) {
  const tokenIdToTokenAddressMapping: { [tokenAddress: string]: string } & {
    [tokenId: string]: string;
  } = {};
  const networkTokens = getNetworkTokens(networkConfig.networkId, tokens);

  for (const tokenAddress of uniqueTokenAddresses) {
    const networkToken = networkTokens.find(
      (token) =>
        token[networkConfig.networkId].address.toLowerCase() ===
        tokenAddress.toLowerCase()
    );

    const coinGeckoId = networkToken!.coinGeckoId;

    tokenIdToTokenAddressMapping[tokenAddress] = coinGeckoId;
    tokenIdToTokenAddressMapping[coinGeckoId] = tokenAddress;
  }

  return tokenIdToTokenAddressMapping;
}

const hyphenBaseUrl = "https://hyphen-v2-api.biconomy.io";

export { hyphenBaseUrl };
