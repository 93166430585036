import useNetworks from "hooks/query/useNetworks";
import useTokens from "hooks/query/useTokens";
import ExitFeesTable from "./components/ExitFeesTable";
import RewardPoolBalance from "./components/RewardPoolBalance";
import SuppliedVsAvailableLiquidity from "./components/SuppliedVsAvailableLiquidity";

function RebalancingDashboard() {
  const { data: networksData } = useNetworks();
  const { data: tokensData } = useTokens();

  const tokensArray = tokensData
    ? Object.keys(tokensData).map(
        (tokenSymbol: string) => tokensData[tokenSymbol]
      )
    : null;

  return (
    <div>
      {networksData && tokensArray
        ? tokensArray.map((token, index) => {
            return (
              <div
                className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4"
                key={`${token.symbol}-${index}`}
              >
                {networksData.map((network) => {
                  if (
                    token[network.chainId] &&
                    token[network.chainId].isSupported
                  ) {
                    return (
                      <SuppliedVsAvailableLiquidity
                        key={`${token.symbol}-${network.name}-${index}`}
                        network={network}
                        token={token}
                      />
                    );
                  }

                  return null;
                })}
              </div>
            );
          })
        : null}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        {networksData && tokensArray
          ? tokensArray.map((token, index) => {
              if (token.symbol !== "AVAX") {
                return (
                  <RewardPoolBalance
                    key={`${token.symbol}-${index}`}
                    networks={networksData}
                    token={token}
                  />
                );
              }

              return null;
            })
          : null}
      </div>

      {networksData && tokensArray ? (
        <ExitFeesTable networks={networksData} tokens={tokensArray} />
      ) : null}
    </div>
  );
}

export default RebalancingDashboard;
