import { ethers } from "ethers";
import { gql, request } from "graphql-request";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { useQuery } from "react-query";

interface IAssetDeposit {
  averageRewardAmountPercent: string;
}

interface IAssetSentEntry {
  averageLpFeePercent: string;
  averageTransferFeePercent: string;
  averageGasFeePercent: string;
}

interface ITableRow {
  network: string;
  tokenSymbol: string;
  averageAvailableLiquidity: number;
  averageSuppliedLiquidity: number;
  percentageSL: number;
  averageState: number;
  averageGasFeePercent: number;
  averageLpFeePercent: number;
  averageRebalancingFeePercent: number;
  averageRewardAmountPercent: number;
}

interface ITableRowData {
  hourlyDeposits: any;
  hourlyAssetSents: any;
  hourlySuppliedLiquidity: any;
  hourlyAvailableLiquidity: any;
}

const currentTimestamp = Math.floor(Date.now() / 1000);
const epochModSecondsInADay = currentTimestamp % 86400;
const todayEpoch = currentTimestamp - epochModSecondsInADay;
const beginningEpoch = todayEpoch - 86400 * 1;

function processTableData(
  network: Network,
  token: Token,
  tableRowData: ITableRowData
): ITableRow {
  const {
    hourlyDeposits,
    hourlyAssetSents,
    hourlySuppliedLiquidity,
    hourlyAvailableLiquidity,
  } = tableRowData;
  const tokenDecimals = token[network.chainId].decimal;
  const { availableLiquidity, count: availableLiquidityCount } =
    hourlyAvailableLiquidity;
  const { suppliedLiquidity, count: suppliedLiquidityCount } =
    hourlySuppliedLiquidity;

  const averageAvailableLiquidity =
    Number.parseFloat(
      ethers.utils.formatUnits(availableLiquidity, tokenDecimals)
    ) / Number.parseFloat(availableLiquidityCount);
  const averageSuppliedLiquidity =
    Number.parseFloat(
      ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
    ) / Number.parseFloat(suppliedLiquidityCount);
  const percentageSL =
    (averageAvailableLiquidity / averageSuppliedLiquidity) * 100;
  const averageState = averageAvailableLiquidity - averageSuppliedLiquidity;

  const { averageRewardAmountPercent: totalRewardAmountPercent } =
    hourlyDeposits.reduce(
      (acc: IAssetDeposit, currValue: IAssetDeposit) => {
        const { averageRewardAmountPercent } = currValue;
        const { averageRewardAmountPercent: prevAverageRewardAmountPercent } =
          acc;
        return {
          averageRewardAmountPercent:
            Number.parseFloat(prevAverageRewardAmountPercent) +
            Number.parseFloat(averageRewardAmountPercent),
        };
      },
      {
        averageRewardAmountPercent: 0,
      }
    );

  const {
    averageGasFeePercent: totalGasFeePercent,
    averageLpFeePercent: totalLpFeePercent,
    averageTransferFeePercent: totalTransferFeePercent,
  } = hourlyAssetSents.reduce(
    (acc: IAssetSentEntry, currValue: IAssetSentEntry) => {
      const {
        averageGasFeePercent,
        averageLpFeePercent,
        averageTransferFeePercent,
      } = currValue;
      const {
        averageGasFeePercent: prevGasFeePercent,
        averageLpFeePercent: prevLpFeePercent,
        averageTransferFeePercent: prevTransferFeePercent,
      } = acc;
      return {
        averageGasFeePercent:
          Number.parseFloat(prevGasFeePercent) +
          Number.parseFloat(averageGasFeePercent),
        averageLpFeePercent:
          Number.parseFloat(prevLpFeePercent) +
          Number.parseFloat(averageLpFeePercent),
        averageTransferFeePercent:
          Number.parseFloat(prevTransferFeePercent) +
          Number.parseFloat(averageTransferFeePercent),
      };
    },
    {
      averageGasFeePercent: 0,
      averageLpFeePercent: 0,
      averageTransferFeePercent: 0,
    }
  );

  const averageRewardAmountPercent = totalRewardAmountPercent
    ? totalRewardAmountPercent / hourlyDeposits.length
    : 0;
  const averageGasFeePercent = totalGasFeePercent
    ? totalGasFeePercent / hourlyAssetSents.length
    : 0;
  const averageLpFeePercent = totalLpFeePercent
    ? totalLpFeePercent / hourlyAssetSents.length
    : 0;
  const averageRebalancingFeePercent = totalTransferFeePercent
    ? totalTransferFeePercent / hourlyAssetSents.length - averageLpFeePercent
    : 0;

  return {
    network: network.name,
    tokenSymbol: token.symbol,
    averageAvailableLiquidity,
    averageSuppliedLiquidity,
    percentageSL,
    averageState,
    averageGasFeePercent,
    averageLpFeePercent,
    averageRebalancingFeePercent,
    averageRewardAmountPercent,
  };
}

function TableRow({ network, token }: { network: Network; token: Token }) {
  const { data: tableRowData } = useQuery(
    [`tableDataOn${network.name}`, token[network.chainId]?.address],
    async () => {
      const {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      } = await request(
        network.v2GraphUrl,
        gql`
            query {
              hourlyDeposits(where:{
                  tokenAddress: "${token[network.chainId]?.address}"
                  timestamp_gt: ${beginningEpoch}
                },
                orderBy:timestamp,
                orderDirection:desc) {
                averageRewardAmountPercent
              }
              hourlyAssetSents(where:{
                  tokenAddress: "${token[network.chainId]?.address}"
                  timestamp_gt: ${beginningEpoch}
                },
                orderBy:timestamp,
                orderDirection:desc) {
                averageLpFeePercent
                averageTransferFeePercent
                averageGasFeePercent
              }
              hourlySuppliedLiquidity(id: "${token[
                network.chainId
              ]?.address.toLowerCase()}") {
                suppliedLiquidity
                count
              }
              hourlyAvailableLiquidity(id: "${token[
                network.chainId
              ]?.address.toLowerCase()}") {
                availableLiquidity
                count
              }
            }
          `
      );
      return {
        hourlyDeposits,
        hourlyAssetSents,
        hourlySuppliedLiquidity,
        hourlyAvailableLiquidity,
      };
    },
    {
      enabled: !!token[network.chainId]?.address,
    }
  );

  const processedTableRowData = tableRowData
    ? processTableData(network, token, tableRowData)
    : null;

  return processedTableRowData ? (
    <tr className="bg-white border-b">
      <td className="px-6 py-4">{token.symbol}</td>
      <td className="px-6 py-4">{network.name}</td>
      <td className="px-6 py-4">
        {Math.floor(
          processedTableRowData.averageSuppliedLiquidity
        ).toLocaleString()}
      </td>
      <td className="px-6 py-4">
        {Math.floor(
          processedTableRowData.averageAvailableLiquidity
        ).toLocaleString()}
      </td>
      <td className="px-6 py-4">
        {Math.round(processedTableRowData.percentageSL)}
      </td>
      <td className="px-6 py-4">
        {Math.floor(processedTableRowData.averageState).toLocaleString()}
      </td>
      <td className="px-6 py-4">
        {processedTableRowData.averageLpFeePercent.toFixed(3)}%
      </td>
      <td className="px-6 py-4">
        {processedTableRowData.averageRebalancingFeePercent.toFixed(3)}%
      </td>
      <td className="px-6 py-4">
        {processedTableRowData.averageRewardAmountPercent.toFixed(3)}%
      </td>
      <td className="px-6 py-4">
        {processedTableRowData.averageGasFeePercent.toFixed(3)}%
      </td>
      <td className="px-6 py-4">
        {(
          processedTableRowData.averageLpFeePercent +
          processedTableRowData.averageRebalancingFeePercent -
          processedTableRowData.averageRewardAmountPercent +
          processedTableRowData.averageGasFeePercent
        ).toFixed(3)}
        %
      </td>
    </tr>
  ) : null;
}

function ExitFeesTable({
  networks,
  tokens,
}: {
  networks: Network[];
  tokens: Token[];
}) {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-4 grid grid-cols-1 gap-4">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3">
              Pool
            </th>
            <th scope="col" className="px-6 py-3">
              Chain
            </th>
            <th scope="col" className="px-6 py-3">
              Average Supplied Liquidity <br />
              (Last hour - AMT. in tokens)
            </th>
            <th scope="col" className="px-6 py-3">
              Average Available Liquidity <br />
              (Last hour - AMT. in tokens)
            </th>
            <th scope="col" className="px-6 py-3">
              Percentage SL
            </th>
            <th scope="col" className="px-6 py-3">
              Average State <br />
              (Last hour - AMT. in tokens)
            </th>
            <th scope="col" className="px-6 py-3">
              Average LP Fee <br />
              (Last 24 hours)
            </th>
            <th scope="col" className="px-6 py-3">
              Average Rebalancing Fee <br />
              (Last 24 hours)
            </th>
            <th scope="col" className="px-6 py-3">
              Average Rebalancing Reward <br />
              (Last 24 hours)
            </th>
            <th scope="col" className="px-6 py-3">
              Average Gas Fee <br />
              (Last 24 hours)
            </th>
            <th scope="col" className="px-6 py-3">
              Average Exit Fee <br />
              (Last 24 hours)
            </th>
          </tr>
        </thead>
        <tbody>
          {tokens.map((token) => {
            return networks.map((network) => {
              return token[network.chainId] &&
                token[network.chainId].isSupported ? (
                <TableRow
                  key={`${token.symbol}-${network.chainId}-exit-fees`}
                  network={network}
                  token={token}
                />
              ) : null;
            });
          })}
        </tbody>
      </table>
    </div>
  );
}

export default ExitFeesTable;
