import RewardPoolBalanceGraphCard from "./RewardPoolBalanceGraphCard";
import { ethers } from "ethers";
import { gql, request } from "graphql-request";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { useQueries } from "react-query";

async function fetchPoolBalance(network: Network, token: Token) {
  if (!token[network.chainId]?.address || !token[network.chainId].isSupported) {
    return null;
  }

  const { incentivePoolBalanceLogEntries } = await request(
    network.v2GraphUrl,
    gql`
      query {
        incentivePoolBalanceLogEntries(
          where: {
            tokenAddress: "${token[network.chainId]?.address.toLowerCase()}"
          }
          orderBy: timestamp
          orderDirection: desc
        ) {
          timestamp
          poolBalance
        }
      }
    `
  );

  return incentivePoolBalanceLogEntries;
}

function RewardPoolBalance({
  networks,
  token,
}: {
  networks: Network[];
  token: Token;
}) {
  const poolBalanceQueries = useQueries(
    networks.map((network) => {
      return {
        queryKey: [`poolBalanceOn${network.name}`, token.symbol],
        queryFn: () => fetchPoolBalance(network, token),
      };
    })
  );

  const hasPoolBalanceQueriesLoaded =
    poolBalanceQueries.length > 0
      ? poolBalanceQueries.every((poolBalanceQuery) => {
          return poolBalanceQuery.status === "success";
        })
      : false;

  const poolBalanceData = hasPoolBalanceQueriesLoaded
    ? networks.map((network, index) => {
        if (poolBalanceQueries[index].data) {
          return poolBalanceQueries[index].data
            .slice(0, 20)
            .reverse()
            .map(
              (
                {
                  poolBalance,
                }: {
                  poolBalance: string;
                },
                index: {
                  index: number;
                }
              ) => {
                return {
                  x: index,
                  y: ethers.utils.formatUnits(
                    poolBalance,
                    token[network.chainId]?.decimal
                  ),
                };
              }
            );
        } else {
          return [];
        }
      })
    : null;

  const graphData = poolBalanceData
    ? networks.map((network, index) => {
        return {
          id: network.name,
          data: poolBalanceData[index],
        };
      })
    : null;

  return graphData ? (
    <RewardPoolBalanceGraphCard
      title={`Reward Pool Balance (${token.symbol})`}
      data={graphData}
    />
  ) : null;
}

export default RewardPoolBalance;
