import DataCard from "components/DataCard/DataCard";
import { INetworkConfig } from "config/config";
import { ethers } from "ethers";
import request, { gql } from "graphql-request";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { NetworkTotalFiatVolume } from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import { NetworkTotalVolume } from "hooks/query/volume/NetworkTotalVolumeQuery";
import React from "react";
import { useQueries } from "react-query";
import { constructNetworkConfig } from "utils/constructNetworkConfig";
import getNetworkTokens from "utils/getNetworkTokens";
import { getTokenFiatPriceData } from "./OverallDayVolume";

interface IOverallTotalVolumeProps {
  networks: Network[];
  tokens: Token[];
}

async function getNetworkTotalVolumeData(
  networkConfig: INetworkConfig,
  tokens: Token[]
) {
  const subgraphResponse = await request(
    networkConfig.subgraphEndpoint,
    gql`
      query {
        depositVolumeCumulativePerChainAndTokens {
          cumulativeAmount
          tokenAddress
        }
      }
    `
  );

  const deduplicatedNetworkCumulativeVolumes = (
    subgraphResponse.depositVolumeCumulativePerChainAndTokens as Array<NetworkTotalVolume>
  ).reduce((acc, curr) => {
    curr.cumulativeAmount = parseFloat(
      parseFloat(
        ethers.utils.formatUnits(
          curr.cumulativeAmount,
          getNetworkTokens(networkConfig.networkId, tokens).find(
            (token) =>
              token[networkConfig.networkId].address.toLowerCase() ===
              curr.tokenAddress.toLowerCase()
          )![networkConfig.networkId].decimal
        )
      ).toFixed(3)
    );
    acc[curr.tokenAddress] = {
      tokenAddress: curr.tokenAddress,
      cumulativeAmount:
        (acc[curr.tokenAddress]?.cumulativeAmount || 0) + curr.cumulativeAmount,
    };
    return acc;
  }, {} as { [tokenAddress: string]: NetworkTotalVolume });

  const networkCumulativeVolumes = Object.values(
    deduplicatedNetworkCumulativeVolumes
  );

  return networkCumulativeVolumes;
}

async function getNetworkTotalVolume(network: Network, tokens: Token[]) {
  const networkConfig = constructNetworkConfig(network, tokens);
  const networkTotalVolumeData = await getNetworkTotalVolumeData(
    networkConfig,
    tokens
  );

  const uniqueTokenAddressesSet = new Set<string>();
  if (networkTotalVolumeData) {
    for (const networkTotalVolume of networkTotalVolumeData) {
      uniqueTokenAddressesSet.add(networkTotalVolume.tokenAddress);
    }
  }
  const uniqueTokenAddresses = Array.from(uniqueTokenAddressesSet);

  const tokenFiatPriceData = await getTokenFiatPriceData(
    networkConfig,
    tokens,
    uniqueTokenAddresses
  );

  if (!networkTotalVolumeData || !tokenFiatPriceData) {
    return undefined;
  }

  const networkTotalFiatVolumes = networkTotalVolumeData.map(
    (networkTotalVolume) => ({
      ...networkTotalVolume,
      cumulativeFiatAmount:
        networkTotalVolume.cumulativeAmount *
        tokenFiatPriceData[networkTotalVolume.tokenAddress],
    })
  );

  return networkTotalFiatVolumes as NetworkTotalFiatVolume[];
}

const OverallTotalVolume: React.FC<IOverallTotalVolumeProps> = ({
  networks,
  tokens,
}) => {
  const networkTotalVolumeQueries = useQueries(
    networks?.map((network) => {
      return {
        queryKey: [`totalVolumeOn${network.name}`, network.chainId],
        queryFn: () => getNetworkTotalVolume(network, tokens),
      };
    }) ?? []
  );

  const isLoading = networkTotalVolumeQueries.some(
    (networkTotalVolumeQuery) => networkTotalVolumeQuery.status === "loading"
  );

  const overallTotalVolume = !isLoading
    ? networkTotalVolumeQueries.reduce((totalVolume, networkVolumeQuery) => {
        if (networkVolumeQuery.data) {
          return (
            totalVolume +
            networkVolumeQuery.data.reduce((acc, currValue) => {
              return acc + currValue.cumulativeFiatAmount;
            }, 0)
          );
        }

        return 0;
      }, 0)
    : undefined;

  const overallTotalVolumeString = overallTotalVolume
    ? "$ " + parseInt(overallTotalVolume.toString()).toLocaleString("en-US")
    : undefined;

  return (
    <DataCard title="Total Volume" body={overallTotalVolumeString || "..."} />
  );
};

export default OverallTotalVolume;
