import React from "react";

import LiquidityGraphCard from "./LiquidityGraphCard";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { constructNetworkConfig } from "utils/constructNetworkConfig";

interface INetworkLiquidityExceptEthereumProps {
  network: Network;
  tokens: Token[];
}

const NetworkLiquidityExceptEthereum: React.FC<
  INetworkLiquidityExceptEthereumProps
> = ({ network, tokens }) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const networkLiquidityQuery = useNetworkTokensLiquidityQuery(networkConfig);

  return (
    <LiquidityGraphCard
      data={networkLiquidityQuery?.data?.filter(
        (tokenLiquidity) => tokenLiquidity.tokenSymbol !== "ETH"
      )}
      title={`${network.name} Liquidity`}
    />
  );
};

export default NetworkLiquidityExceptEthereum;
