import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import useNetworkTotalFiatVolumeQuery, {
  NetworkTotalFiatVolume,
} from "hooks/query/volume/NetworkTotalFiatVolumeQuery";
import React, { useMemo } from "react";
import { constructNetworkConfig } from "utils/constructNetworkConfig";
import NetworkDataCard from "./NetworkDataCard";

interface INetworkTotalVolumeCardProps {
  network: Network;
  tokens: Token[];
}

const NetworkTotalVolumeCard: React.FunctionComponent<
  INetworkTotalVolumeCardProps
> = ({ network, tokens }) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const totalVolumeQuery = useNetworkTotalFiatVolumeQuery(networkConfig);

  const cumulativeVolume = useMemo(() => {
    if (!totalVolumeQuery.data) return undefined;

    return (totalVolumeQuery.data as NetworkTotalFiatVolume[]).reduce(
      (acc, curr) => {
        return acc + curr.cumulativeFiatAmount || 0;
      },
      0
    );
  }, [totalVolumeQuery.data]);

  return (
    <NetworkDataCard
      body={
        cumulativeVolume
          ? `$ ${Number(cumulativeVolume.toFixed(0)).toLocaleString("en-US")}`
          : "..."
      }
      network={network}
      title="Total Volume"
    />
  );
};

export default NetworkTotalVolumeCard;
