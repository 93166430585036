import React, { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

export interface IDataCardProps {
  bgColor?: string;
  title?: ReactNode;
  body?: ReactNode;
  footer?: ReactNode;
  containerClassName?: string;
  titleClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
}

const DataCard: React.FunctionComponent<IDataCardProps> = ({
  bgColor,
  title,
  body,
  footer,
  children,
  bodyClassName,
  containerClassName,
  footerClassName,
  titleClassName,
}) => {
  return (
    <div
      className={twMerge(
        "bg-hyphen-purple/10 px-5 py-8 w-full rounded-md flex-col gap-4 flex relative",
        containerClassName
      )}
      style={{ backgroundColor: bgColor?.slice(0, -2) }}
    >
      <div
        className={twMerge("text-lg font-medium text-black/50", titleClassName)}
      >
        {title}
      </div>
      <div className={twMerge("text-3xl text-hyphen-purple", bodyClassName)}>
        {body}
      </div>
      {footer}
      {children}
    </div>
  );
};

export default DataCard;
