import { INetworkConfig } from 'config/config';
import { gql, request } from 'graphql-request';
import { useQuery } from 'react-query';
import { constructNetworkConfig } from 'utils/constructNetworkConfig';
import { Network } from './useNetworks';
import { Token } from './useTokens';

export default function useTransactionByDepositHashQuery(
  fromChainNetworkConfig: INetworkConfig,
  depositHash: string,
  networks: Network[],
  tokens: Token[]
  // options?: UseQueryOptions<string, Error, string, string[]>
) {
  return useQuery(
    [
      'transactionByDepositHash',
      fromChainNetworkConfig.networkName,
      depositHash,
    ],

    async () => {
      const subgraphDepositResponse = await request(
        fromChainNetworkConfig.subgraphEndpoint,
        gql`
          {
            deposit(id: "${depositHash}") {
              timestamp
              id
              toChainID
              rewardAmount
              amount
              sender
              tokenAddress
            }
          }
        `
      );

      if (!subgraphDepositResponse.deposit || !networks) {
        return undefined;
      }

      const toChainNetworkConfig: INetworkConfig = constructNetworkConfig(
        networks.find(
          (network) =>
            network.chainId ===
            Number(subgraphDepositResponse.deposit.toChainID)
        )!,
        tokens
      );

      const subgraphExitResponse = await request(
        toChainNetworkConfig.subgraphEndpoint,
        gql`
            {
              assetSentToUserLogEntries(where: { depositHash: "${depositHash.toLowerCase()}" }) {
                id
                timestamp
                tokenAddress
                amount
                transferredAmount
                receiver
              }
            }
          `
      );

      if (
        !subgraphExitResponse.assetSentToUserLogEntries ||
        !subgraphExitResponse.assetSentToUserLogEntries[0]
      ) {
        return {
          from: subgraphDepositResponse.deposit,
        };
      }

      return {
        from: subgraphDepositResponse.deposit,
        to: subgraphExitResponse.assetSentToUserLogEntries[0],
      };
    },
    {
      // ...options,
    }
  );
}
