import Topbar from "components/Topbar";
import useNetworks from "hooks/query/useNetworks";
import useTokens from "hooks/query/useTokens";
import LastTransactionTimes from "./components/LastTransactionTimes";
import NetworkDayVolumeCard from "./components/NetworkDayVolume";
import NetworkFiatLiquidity from "./components/NetworkFiatLiquidity";
import NetworkLiquidityEthereum from "./components/NetworkLiquidityEthereum";
import NetworkLiquidityExceptEthereum from "./components/NetworkLiquidityExceptEthereum";
import NetworkTotalVolumeCard from "./components/NetworkTotalVolumeCard";
import NetworkUniqueUsersCard from "./components/NetworkUniqueUsers";
import OverallDailyLpFeeGraph from "./components/OverallDailyLPFeeGraph";
import OverallDailyVolumeGraph from "./components/OverallDailyVolumeGraph";
import OverallDayVolume from "./components/OverallDayVolume";
import OverallTotalVolume from "./components/OverallTotalVolume";
import OverallUniqueUsers from "./components/OverallUniqueUsers";

function Home() {
  const { data: networks } = useNetworks();
  const { data: tokensData } = useTokens();

  if (!networks || !tokensData) return <div>Loading...</div>;

  const tokens = Object.keys(tokensData)
    .map((tokenSymbol: string) => tokensData[tokenSymbol])
    .filter((token) => token.symbol !== "AVAX");

  return (
    <>
      <Topbar networks={networks} />
      <div className="grid md:grid-cols-2 xl:grid-cols-4 grid-cols-1 gap-4">
        <OverallDayVolume networks={networks} tokens={tokens} />
        <OverallTotalVolume networks={networks} tokens={tokens} />
        <LastTransactionTimes networks={networks} tokens={tokens} />
        <OverallUniqueUsers networks={networks} />
      </div>

      {networks.map((network) => (
        <div
          className="grid lg:grid-cols-3 grid-cols-1 gap-4"
          key={network.chainId}
        >
          <NetworkDayVolumeCard network={network} tokens={tokens} />
          <NetworkTotalVolumeCard network={network} tokens={tokens} />
          <NetworkUniqueUsersCard network={network} tokens={tokens} />
        </div>
      ))}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <OverallDailyVolumeGraph networks={networks} tokens={tokens} />
        <OverallDailyLpFeeGraph networks={networks} tokens={tokens} />
      </div>

      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 pb-8">
        {networks.map((network) => (
          <NetworkLiquidityExceptEthereum
            key={network.chainId}
            network={network}
            tokens={tokens}
          />
        ))}
      </div>

      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 pb-8">
        {networks.map((network) => (
          <NetworkLiquidityEthereum
            key={network.chainId}
            network={network}
            tokens={tokens}
          />
        ))}
      </div>

      <div className="grid xl:grid-cols-3 grid-cols-1 gap-4 pb-8">
        {networks.map((network) => (
          <NetworkFiatLiquidity
            key={network.chainId}
            network={network}
            tokens={tokens}
          />
        ))}
      </div>
    </>
  );
}

export default Home;
