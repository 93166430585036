import React from "react";

import FiatLiquidityGraphCard from "pages/rebalancing-dashboard/components/FiatLiquidityGraphCard";
import useNetworkTokensFiatLiquidityQuery from "hooks/query/liquidity/NetworkTokensFiatLiquidityQuery";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { constructNetworkConfig } from "utils/constructNetworkConfig";

interface INetworkFiatLiquidityProps {
  network: Network;
  tokens: Token[];
}

const NetworkFiatLiquidity: React.FC<INetworkFiatLiquidityProps> = ({
  network,
  tokens,
}) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const fiatLiquidity = useNetworkTokensFiatLiquidityQuery(networkConfig);

  return (
    <FiatLiquidityGraphCard
      data={fiatLiquidity.data}
      title={`${network.name} Liquidity (USD)`}
    />
  );
};

export default NetworkFiatLiquidity;
