import { INetworkConfig } from "config/config";
import { ethers } from "ethers";
import { gql, request } from "graphql-request";
import { useQuery } from "react-query";
import getNetworkTokens from "utils/getNetworkTokens";
import useTokens from "../useTokens";

export type NetworkTotalVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
};

export default function useNetworkTotalVolumeQuery(
  networkConfig: INetworkConfig
) {
  const { data: tokensData } = useTokens();

  const tokens = tokensData
    ? Object.keys(tokensData)
        .map((tokenSymbol: string) => tokensData[tokenSymbol])
        .filter((token) => token.symbol !== "AVAX")
    : [];

  return useQuery<NetworkTotalVolume[]>(
    ["cumulativeVolume", networkConfig.networkName],
    async () => {
      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          query {
            depositVolumeCumulativePerChainAndTokens {
              cumulativeAmount
              tokenAddress
            }
          }
        `
      );

      const deduplicatedNetworkCumulativeVolumes = (
        subgraphResponse.depositVolumeCumulativePerChainAndTokens as Array<NetworkTotalVolume>
      ).reduce((acc, curr) => {
        curr.cumulativeAmount = parseFloat(
          parseFloat(
            ethers.utils.formatUnits(
              curr.cumulativeAmount,
              getNetworkTokens(networkConfig.networkId, tokens).find(
                (token) =>
                  token[networkConfig.networkId].address.toLowerCase() ===
                  curr.tokenAddress.toLowerCase()
              )![networkConfig.networkId].decimal
            )
          ).toFixed(3)
        );
        acc[curr.tokenAddress] = {
          tokenAddress: curr.tokenAddress,
          cumulativeAmount:
            (acc[curr.tokenAddress]?.cumulativeAmount || 0) +
            curr.cumulativeAmount,
        };
        return acc;
      }, {} as { [tokenAddress: string]: NetworkTotalVolume });

      const networkCumulativeVolumes = Object.values(
        deduplicatedNetworkCumulativeVolumes
      );

      return networkCumulativeVolumes;
    },
    {
      staleTime: 60000,
    }
  );
}
