import { INetworkConfig, INetworkTokenConfig } from "config/config";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";

// TODO: this can be removed once INetworkConfig &
// INetworkTokenConfig are removed.
function constructNetworkConfig(
  network: Network,
  tokens: Token[]
): INetworkConfig {
  const {
    chainId,
    contracts: {
      hyphen: { liquidityPool, liquidityProviders },
    },
    name,
    rpc,
    v2GraphUrl,
  } = network;

  const networkTokens = tokens
    .filter((token) => {
      return token[chainId];
    })
    .map((token) => {
      return {
        coinGeckoId: token.coinGeckoId,
        contractAddress: token[chainId].address,
        decimals: token[chainId].decimal,
        symbol: token.symbol,
      };
    }) as INetworkTokenConfig[];

  return {
    liquidityPoolContractAddress: liquidityPool,
    liquidityProvidersContractAddress: liquidityProviders,
    subgraphEndpoint: v2GraphUrl,
    networkName: name,
    tokens: networkTokens,
    networkId: chainId,
    rpcUrl: rpc,
  };
}

export { constructNetworkConfig };
