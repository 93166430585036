import { Network } from "hooks/query/useNetworks";
import * as React from "react";
import DataCard from "./DataCard";

interface INetworkCardProps {
  body?: React.ReactNode;
  footer?: React.ReactNode;
  network: Network;
  title?: React.ReactNode;
}

const NetworkDataCard: React.FunctionComponent<INetworkCardProps> = (props) => {
  return (
    <DataCard
      bgColor={props.network.chainColor}
      titleClassName="text-white/80"
      bodyClassName="text-white"
      footerClassName="text-red-400"
      {...props}
    >
      <div className="absolute top-6 right-6 flex gap-1 items-center text-white text-xs pt-3 pr-3 font-medium">
        {props.network.name}
      </div>
    </DataCard>
  );
};

export default NetworkDataCard;
