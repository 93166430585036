import HyphenLogo from "assets/images/hyphen-logo.svg";
import Select from "components/Inputs/Select";
import { Network } from "hooks/query/useNetworks";
import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate, useSearchParams } from "react-router-dom";

interface ITopbarProps {
  networks: Network[];
}

const Topbar: React.FunctionComponent<ITopbarProps> = ({ networks }) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const networkOptions = networks.map((network) => ({
    title: network.name,
  }));

  const [activeSearchNetwork, setActiveSearchNetwork] = useState<any>(
    networkOptions.find(
      (option) => option.title === searchParams.get("network")
    ) || networkOptions[0]
  );

  const [searchQuery, setSearchQuery] = useState<string>(
    searchParams.get("depositHash") || ""
  );

  return (
    <div className="bg-black bg-opacity-[0.03] grid py-8 px-6 rounded-md grid-cols-1 gap-y-6 md:grid-cols-[auto_1fr] items-center justify-between">
      <a href="https://hyphen.biconomy.io">
        <img src={HyphenLogo} alt="Hyphen" className="h-8 w-auto" />
      </a>
      <div className="flex items-center gap-2 md:justify-end justify-between">
        <div className="flex items-stretch border-hyphen-purple/20 border rounded-lg">
          <Select
            selected={activeSearchNetwork}
            options={networkOptions}
            onChange={setActiveSearchNetwork}
            className="rounded-l-lg rounded-r-none flex-grow py-3 border-t-0 border-l-0 border-b-0 border-r border-hyphen-purple/20"
          />
          <form
            className="flex bg-white rounded-r-lg relative"
            onSubmit={(e) => {
              e.preventDefault();

              if (searchQuery && searchQuery !== "") {
                navigate(
                  `/search?depositHash=${searchQuery}&network=${activeSearchNetwork.title}`,
                  {
                    replace: true,
                  }
                );
              }
            }}
          >
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by deposit hash"
              className="border-none border border-l-0 rounded-r-lg pl-4 pr-10 py-2 outline-none md:w-80"
            />
            <button type="submit">
              <AiOutlineSearch className="text-hyphen-purple/20 absolute inset-y-0 mt-2 right-0 mr-2 h-6 w-auto" />
            </button>
          </form>
        </div>
        <div className="font-medium text-black/30">v2</div>
      </div>
    </div>
  );
};

export default Topbar;
