import { INetworkConfig } from "config/config";
import { gql, request } from "graphql-request";
import { useQuery } from "react-query";

export default function useLatestTransactionTimeQuery(
  fromChainNetworkConfig: INetworkConfig,
  toChainNetworkConfig: INetworkConfig
  // options?: UseQueryOptions<string, Error, string, string[]>
) {
  return useQuery(
    [
      "latestTransactionTime",
      fromChainNetworkConfig.networkName,
      toChainNetworkConfig.networkName,
    ],

    async () => {
      const subgraphExitResponse = await request(
        toChainNetworkConfig.subgraphEndpoint,
        gql`
          {
            assetSentToUserLogEntries(
              where: { fromChainId: "${fromChainNetworkConfig.networkId}" }
              orderBy: timestamp
              orderDirection: desc
              first: 1
            ) {
              depositHash
              timestamp
            }
          }
        `
      );

      if (
        !subgraphExitResponse ||
        subgraphExitResponse.assetSentToUserLogEntries.length === 0
      ) {
        return "N/A";
      }

      const { depositHash, timestamp: exitTimestamp } =
        subgraphExitResponse.assetSentToUserLogEntries[0];

      const subgraphDepositResponse = await request(
        fromChainNetworkConfig.subgraphEndpoint,
        gql`
          {
            deposit(id: "${depositHash}") {
              timestamp
            }
          }
        `
      );

      if (!subgraphDepositResponse.deposit) {
        return "N/A";
      }

      const depositTimestamp = subgraphDepositResponse.deposit.timestamp;

      const transactionTime =
        parseInt(exitTimestamp) - parseInt(depositTimestamp);
      return `${transactionTime} seconds`;
    },
    {
      // ...options,
    }
  );
}
