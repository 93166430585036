import React from "react";

import useNetworkUniqueUsersQuery from "hooks/query/uniqueUsers/NetworkUniqueUsersQuery";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { constructNetworkConfig } from "utils/constructNetworkConfig";
import NetworkDataCard from "./NetworkDataCard";

interface INetworkUniqueUsersCardProps {
  network: Network;
  tokens: Token[];
}

const NetworkUniqueUsersCard: React.FC<INetworkUniqueUsersCardProps> = ({
  network,
  tokens,
}) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const uniqueUsersQuery = useNetworkUniqueUsersQuery(networkConfig);

  return (
    <NetworkDataCard
      body={uniqueUsersQuery.data || "..."}
      network={network}
      title="Unique Users"
    />
  );
};

export default NetworkUniqueUsersCard;
