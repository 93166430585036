import Topbar from 'components/Topbar';
import { INetworkConfig } from 'config/config';
import useTransactionByDepositHashQuery from 'hooks/query/TransactionByDepositHashQuery';
import useNetworks, { Network } from 'hooks/query/useNetworks';
import useTokens, { Token } from 'hooks/query/useTokens';
import * as React from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { constructNetworkConfig } from 'utils/constructNetworkConfig';

const Search: React.FunctionComponent = () => {
  let [searchParams] = useSearchParams();
  const depositHash = searchParams.get('depositHash') || '';
  const networkName = searchParams.get('network') || '';

  const { data: networks } = useNetworks();
  const { data: tokensData } = useTokens();

  if (!networks || !tokensData) return <div>Loading...</div>;

  const tokens = Object.keys(tokensData)
    .map((tokenSymbol: string) => tokensData[tokenSymbol])
    .filter((token) => token.symbol !== 'AVAX');

  const fromChainNetworkConfig = constructNetworkConfig(
    networks.find((network) => network.name === networkName)!,
    tokens
  );

  if (!depositHash || !networkName || !fromChainNetworkConfig) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <Topbar key={`${depositHash}-${networkName}`} networks={networks} />
      <SearchResults
        fromChainConfig={fromChainNetworkConfig}
        depositHash={depositHash}
        networks={networks}
        tokens={tokens}
      />
    </>
  );
};

interface ISearchResultsProps {
  fromChainConfig: INetworkConfig;
  depositHash: string;
  networks: Network[];
  tokens: Token[];
}

const SearchResults: React.FunctionComponent<ISearchResultsProps> = ({
  fromChainConfig,
  depositHash,
  networks,
  tokens,
}) => {
  const transactionByDepositHashQuery = useTransactionByDepositHashQuery(
    fromChainConfig,
    depositHash,
    networks,
    tokens
  );

  if (transactionByDepositHashQuery.isLoading) {
    return <>...</>;
  }

  if (!transactionByDepositHashQuery.data) {
    return <p>No Deposit found</p>;
  }

  return (
    <div className="rounded-md border-hyphen-purple/20 border p-8 flex flex-col gap-10">
      <div className="text-xl text-black/70 font-medium">Transfer Details</div>
      <div className="flex flex-col text-base gap-8">
        <div className="flex flex-col gap-4">
          <div className="text-hyphen-purple text-lg font-medium">Deposit</div>
          <div className="grid grid-cols-[auto_1fr] gap-0.5 text-black/80 bg-black/10 border-black/10 border">
            {Object.entries(transactionByDepositHashQuery.data.from).map(
              ([key, value]) => {
                if (key === 'timestamp') {
                  console.log(value);

                  const date = new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'full',
                    timeStyle: 'long',
                  }).format(new Date((value as number) * 1000));

                  return (
                    <>
                      <div className="font-medium bg-white px-6 py-3">
                        {key}
                      </div>
                      <div className="bg-white px-6 py-3">{date as string}</div>
                    </>
                  );
                }

                return (
                  <>
                    <div className="font-medium bg-white px-6 py-3">{key}</div>
                    <div className="bg-white px-6 py-3">{value as string}</div>
                  </>
                );
              }
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <div className="text-hyphen-purple text-lg font-medium">Exit</div>
          <div className="grid grid-cols-[auto_1fr] gap-0.5 text-black/80 bg-black/10 border-black/10 border">
            {Object.entries(transactionByDepositHashQuery.data.to).map(
              ([key, value]) => {
                if (key === 'timestamp') {
                  const date = new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'full',
                    timeStyle: 'long',
                  }).format(new Date((value as number) * 1000));

                  return (
                    <>
                      <div className="font-medium bg-white px-6 py-3">
                        {key}
                      </div>
                      <div className="bg-white px-6 py-3">{date as string}</div>
                    </>
                  );
                }

                return (
                  <>
                    <div className="font-medium bg-white px-6 py-3">{key}</div>
                    <div className="bg-white px-6 py-3">{value as string}</div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
