import LiquidityGraphCard from "./LiquidityGraphCard";
import useNetworkTokensLiquidityQuery from "hooks/query/liquidity/NetworkTokensLiquidityQuery";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import React from "react";
import { constructNetworkConfig } from "utils/constructNetworkConfig";

interface INetworkLiquidityEthereumProps {
  network: Network;
  tokens: Token[];
}

const NetworkLiquidityEthereum: React.FC<INetworkLiquidityEthereumProps> = ({
  network,
  tokens,
}) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const networkLiquidity = useNetworkTokensLiquidityQuery(networkConfig);

  return (
    <LiquidityGraphCard
      data={networkLiquidity.data?.filter(
        (tokenLiquidity) => tokenLiquidity.tokenSymbol === "ETH"
      )}
      title={`${network.name} Liquidity`}
    />
  );
};

export default NetworkLiquidityEthereum;
