import SLVsALGraphCard from "./SLVsALGraphCard";
import { ethers } from "ethers";
import { gql, request } from "graphql-request";
import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import { useQuery } from "react-query";

function SuppliedVsAvailableLiquidity({
  network,
  token,
}: {
  network: Network;
  token: Token;
}) {
  // Prepare data for the graph.
  const { data: liquidityData } = useQuery(
    [`liquidityDataOn${network.name}`, token[network.chainId].address],
    async () => {
      const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
        await request(
          network.v2GraphUrl,
          gql`
            query {
              availableLiquidityLogEntries(
                where: {
                tokenAddress: "${token[network.chainId]?.address.toLowerCase()}"
              }
                orderBy: timestamp
                orderDirection: desc
              ) {
                timestamp
                tokenAddress
                availableLiquidity
              }
              hourlySuppliedLiquidity(
                id: "${token[network.chainId]?.address.toLowerCase()}"
              ) {
                suppliedLiquidity
              }
            }
          `
        );
      return { availableLiquidityLogEntries, hourlySuppliedLiquidity };
    },
    {
      enabled: !!token[network.chainId]?.address,
    }
  );

  let graphData = null;
  if (liquidityData) {
    const { availableLiquidityLogEntries, hourlySuppliedLiquidity } =
      liquidityData;
    const { suppliedLiquidity } = hourlySuppliedLiquidity ?? {};
    const tokenDecimals = token[network.chainId].decimal;
    const formattedSuppliedLiquidity = suppliedLiquidity
      ? Number.parseFloat(
          ethers.utils.formatUnits(suppliedLiquidity, tokenDecimals)
        )
      : 0;
    const availableLiquidityArray = availableLiquidityLogEntries
      ? availableLiquidityLogEntries
          .slice(0, 20)
          .reverse()
          .map(
            ({
              timestamp,
              availableLiquidity,
            }: {
              timestamp: string;
              availableLiquidity: string;
            }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: ethers.utils.formatUnits(availableLiquidity, tokenDecimals),
            })
          )
      : [];
    const suppliedLiquidityArray =
      availableLiquidityLogEntries && suppliedLiquidity
        ? availableLiquidityLogEntries
            .slice(0, 20)
            .reverse()
            .map(({ timestamp }: { timestamp: string }) => ({
              x: new Intl.DateTimeFormat("en-GB", {
                dateStyle: "short",
                timeStyle: "medium",
              }).format(new Date(Number.parseFloat(timestamp) * 1000)),
              y: formattedSuppliedLiquidity,
            }))
        : [];
    graphData =
      availableLiquidityArray && suppliedLiquidityArray
        ? [
            {
              id: "Available",
              color: "hsl(223, 70%, 50%)",
              data: availableLiquidityArray,
            },
            {
              id: "Supplied",
              color: "hsl(335, 70%, 50%)",
              data: suppliedLiquidityArray,
            },
          ]
        : [];
  }

  return graphData ? (
    <SLVsALGraphCard
      data={graphData}
      title={`SL vs AL (${token.symbol} on ${network.name})`}
    />
  ) : null;
}

export default SuppliedVsAvailableLiquidity;
