import axios from 'axios';
import DataCard from 'components/DataCard/DataCard';
import {
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from 'config/config';
import { ethers } from 'ethers';
import request, { gql } from 'graphql-request';
import { Network } from 'hooks/query/useNetworks';
import { Token } from 'hooks/query/useTokens';
import {
  NetworkDayFiatVolume,
  NetworkDayVolume,
} from 'hooks/query/volume/NetworkDayVolumeQuery';
import React from 'react';
import { useQueries } from 'react-query';
import { constructNetworkConfig } from 'utils/constructNetworkConfig';
import getNetworkTokens from 'utils/getNetworkTokens';

interface IOverallDayVolumeProps {
  networks: Network[];
  tokens: Token[];
}

async function getNetworkDayVolumeData(
  networkConfig: INetworkConfig,
  tokens: Token[]
) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const epochModSecondsInAnHour = currentTimestamp % 3600;
  const hourEpoch = currentTimestamp - epochModSecondsInAnHour;
  const beginningEpoch = hourEpoch - 3600 * 24 - 1;

  const subgraphResponse = await request(
    networkConfig.subgraphEndpoint,
    gql`
      query {
        hourlyDepositVolumePerChainAndTokens(
          orderBy: timestamp
          orderDirection: desc
          where: { timestamp_gt: ${beginningEpoch} }
        ) {
          tokenAddress
          cumulativeAmount
          timestamp
        }
      }
    `
  );

  const deduplicatedNetworkDayVolumes = (
    subgraphResponse.hourlyDepositVolumePerChainAndTokens as Array<NetworkDayVolume>
  ).reduce((acc, curr) => {
    curr.cumulativeAmount = parseFloat(
      parseFloat(
        ethers.utils.formatUnits(
          curr.cumulativeAmount,
          getNetworkTokens(networkConfig.networkId, tokens).find(
            (token) =>
              token[networkConfig.networkId].address.toLowerCase() ===
              curr.tokenAddress.toLowerCase()
          )![networkConfig.networkId].decimal
        )
      ).toFixed(3)
    );
    acc[curr.tokenAddress] = {
      tokenAddress: curr.tokenAddress,
      cumulativeAmount:
        (acc[curr.tokenAddress]?.cumulativeAmount || 0) + curr.cumulativeAmount,
    };
    return acc;
  }, {} as { [tokenAddress: string]: NetworkDayVolume });

  const networkDayVolumes: NetworkDayVolume[] = Object.values(
    deduplicatedNetworkDayVolumes
  );

  return networkDayVolumes;
}

export async function getTokenFiatPriceData(
  networkConfig: INetworkConfig,
  tokens: Token[],
  uniqueTokenAddresses: string[]
) {
  if (!uniqueTokenAddresses) return undefined;
  const tokenIdToContractAddressMapping = getTokenIdToContractAddressMapping(
    uniqueTokenAddresses,
    networkConfig,
    tokens
  );
  const rawRates = await axios.get(
    `https://pro-api.coingecko.com/api/v3/simple/price`,
    {
      params: {
        ids: Object.values(tokenIdToContractAddressMapping).join(','),
        vs_currencies: 'USD',
        x_cg_pro_api_key: process.env.REACT_APP_COINGECKO_ID,
      },
    }
  );

  const tokenRates: { [tokenAddress: string]: number } = {};
  const rates = rawRates.data as { [tokenId: string]: { usd: number } };
  for (const [tokenId, { usd }] of Object.entries(rates)) {
    tokenRates[tokenIdToContractAddressMapping[tokenId]] = parseFloat(
      usd as unknown as string
    );
  }

  return tokenRates;
}

async function getNetworkDayVolume(network: Network, tokens: Token[]) {
  const networkConfig = constructNetworkConfig(network, tokens);
  const networkDayVolumeData = await getNetworkDayVolumeData(
    networkConfig,
    tokens
  );

  const uniqueTokenAddressesSet = new Set<string>();
  if (networkDayVolumeData) {
    for (const networkDayVolume of networkDayVolumeData) {
      uniqueTokenAddressesSet.add(networkDayVolume.tokenAddress);
    }
  }
  const uniqueTokenAddresses = Array.from(uniqueTokenAddressesSet);

  const tokenFiatPriceData = await getTokenFiatPriceData(
    networkConfig,
    tokens,
    uniqueTokenAddresses
  );

  if (!networkDayVolumeData || !tokenFiatPriceData) {
    return undefined;
  }

  const networkDayFiatVolumes = networkDayVolumeData.map(
    (networkDayVolume) => ({
      ...networkDayVolume,
      cumulativeFiatAmount:
        networkDayVolume.cumulativeAmount *
        tokenFiatPriceData[networkDayVolume.tokenAddress],
    })
  );

  return networkDayFiatVolumes as NetworkDayFiatVolume[];
}

const OverallDayVolume: React.FC<IOverallDayVolumeProps> = ({
  networks,
  tokens,
}) => {
  const networkDayVolumeQueries = useQueries(
    networks?.map((network) => {
      return {
        queryKey: [`dayVolumeOn${network.name}`, network.chainId],
        queryFn: () => getNetworkDayVolume(network, tokens),
      };
    }) ?? []
  );

  const isLoading = networkDayVolumeQueries.some(
    (networkDayVolumeQuery) => networkDayVolumeQuery.status === 'loading'
  );

  const overallDayVolume = !isLoading
    ? networkDayVolumeQueries.reduce((totalDayVolume, networkVolumeQuery) => {
        if (networkVolumeQuery.data) {
          return (
            totalDayVolume +
            networkVolumeQuery.data.reduce((acc, currValue) => {
              return acc + currValue.cumulativeFiatAmount;
            }, 0)
          );
        }

        return 0;
      }, 0)
    : undefined;

  const overallDayVolumeString = overallDayVolume
    ? '$ ' + parseInt(overallDayVolume.toString()).toLocaleString('en-US')
    : undefined;

  return (
    <DataCard title="24 Hour Volume" body={overallDayVolumeString || '...'} />
  );
};

export default OverallDayVolume;
