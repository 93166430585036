import DataCard from 'components/DataCard/DataCard';
import Select, { Option } from 'components/Inputs/Select';
import {
  addSeconds,
  Duration,
  format,
  formatDistance,
  formatDuration,
  intervalToDuration,
} from 'date-fns';
import useLatestTransactionTimeQuery from 'hooks/query/LatestTransactionTimeQuery';
import { Network } from 'hooks/query/useNetworks';
import { Token } from 'hooks/query/useTokens';
import React, { useEffect, useMemo, useState } from 'react';
import { constructNetworkConfig } from 'utils/constructNetworkConfig';

interface ILastTransactionTimesProps {
  networks: Network[];
  tokens: Token[];
}

function formatTime(duration: Duration) {
  return formatDuration({
    minutes: duration.minutes,
    seconds: duration.seconds,
  });
}

const LastTransactionTimes: React.FC<ILastTransactionTimesProps> = ({
  networks,
  tokens,
}) => {
  const networkOptions = networks.map((network) => ({
    title: network.name,
  }));

  const [fromChain, setFromChain] = useState<Option>(networkOptions[0]);

  const toChainOptions = useMemo(
    () =>
      networkOptions.filter(
        (networkOption) => networkOption.title !== fromChain.title
      ),
    [fromChain.title, networkOptions]
  );

  const [toChain, setToChain] = useState<Option>(toChainOptions[0]);

  useEffect(() => {
    if (toChain.title === fromChain.title) {
      setToChain(toChainOptions[0]);
    }
  }, [fromChain, toChain, toChainOptions]);

  const fromChainNetwork = constructNetworkConfig(
    networks.find((network) => network.name === fromChain.title)!,
    tokens
  );

  const toChainNetwork = constructNetworkConfig(
    networks.find((network) => network.name === toChain.title)!,
    tokens
  );

  const lastTransactionTimeQuery = useLatestTransactionTimeQuery(
    fromChainNetwork,
    toChainNetwork
  );

  const duration = lastTransactionTimeQuery.data
    ? intervalToDuration({
        start: 0,
        end: Number.parseInt(lastTransactionTimeQuery.data) * 1000,
      })
    : 0;
  const formattedTime = duration ? formatTime(duration) : 0;

  return (
    <DataCard
      title="Last Transaction Time"
      body={
        <div className="flex flex-col gap-4">
          <div className="text-xl">{formattedTime ? formattedTime : '...'}</div>
          <div className="grid grid-cols-2 text-lg gap-4">
            <Select
              options={networkOptions}
              onChange={setFromChain}
              selected={fromChain}
            />
            <Select
              options={toChainOptions}
              onChange={setToChain}
              selected={toChain}
            />
          </div>
        </div>
      }
    />
  );
};

export default LastTransactionTimes;
