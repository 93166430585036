import { INetworkConfig } from "config/config";
import { useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { ethers } from "ethers";
import useTokens from "../useTokens";
import getNetworkTokens from "utils/getNetworkTokens";

export type NetworkDayVolume = {
  tokenAddress: string;
  cumulativeAmount: number;
};

export type NetworkDayFiatVolume = NetworkDayVolume & {
  cumulativeFiatAmount: number;
};

export default function useNetworkDayVolumeQuery(
  networkConfig: INetworkConfig
) {
  const { data: tokensData } = useTokens();

  const tokens = tokensData
    ? Object.keys(tokensData)
        .map((tokenSymbol: string) => tokensData[tokenSymbol])
        .filter((token) => token.symbol !== "AVAX")
    : [];

  return useQuery<NetworkDayVolume[]>(
    ["dayVolume", networkConfig.networkName],
    async () => {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const epochModSecondsInAnHour = currentTimestamp % 3600;
      const hourEpoch = currentTimestamp - epochModSecondsInAnHour;
      const beginningEpoch = hourEpoch - 3600 * 24 - 1;

      const subgraphResponse = await request(
        networkConfig.subgraphEndpoint,
        gql`
          query {
            hourlyDepositVolumePerChainAndTokens(
              orderBy: timestamp
              orderDirection: desc
              where: { timestamp_gt: ${beginningEpoch} }
            ) {
              tokenAddress
              cumulativeAmount
              timestamp
            }
          }
        `
      );

      const deduplicatedNetworkDayVolumes = (
        subgraphResponse.hourlyDepositVolumePerChainAndTokens as Array<NetworkDayVolume>
      ).reduce((acc, curr) => {
        curr.cumulativeAmount = parseFloat(
          parseFloat(
            ethers.utils.formatUnits(
              curr.cumulativeAmount,
              getNetworkTokens(networkConfig.networkId, tokens).find(
                (token) =>
                  token[networkConfig.networkId].address.toLowerCase() ===
                  curr.tokenAddress.toLowerCase()
              )![networkConfig.networkId].decimal
            )
          ).toFixed(3)
        );
        acc[curr.tokenAddress] = {
          tokenAddress: curr.tokenAddress,
          cumulativeAmount:
            (acc[curr.tokenAddress]?.cumulativeAmount || 0) +
            curr.cumulativeAmount,
        };
        return acc;
      }, {} as { [tokenAddress: string]: NetworkDayVolume });

      const networkDayVolumes: NetworkDayVolume[] = Object.values(
        deduplicatedNetworkDayVolumes
      );

      return networkDayVolumes;
    },
    {
      staleTime: 60000,
    }
  );
}
