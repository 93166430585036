import { ResponsiveBar } from '@nivo/bar';
import axios from 'axios';
import {
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from 'config/config';
import { format } from 'date-fns';
import { ethers } from 'ethers';
import request, { gql } from 'graphql-request';
import { NetworkDailyFiatFee } from 'hooks/query/fee/NetworkDailyFiatFeeQuery';
import { Network } from 'hooks/query/useNetworks';
import { Token } from 'hooks/query/useTokens';
import { CoinGeckoHistoricalPrice } from 'hooks/query/volume/utils';
import * as React from 'react';
import { useQueries } from 'react-query';
import { constructNetworkConfig } from 'utils/constructNetworkConfig';
import getNetworkTokens from 'utils/getNetworkTokens';

interface IOverallDailyLPFeeGraphProps {
  networks: Network[];
  tokens: Token[];
}

type NetworkDailyFee = {
  cumulativeLpFee: number;
  cumulativeGasFee: number;
  cumulativeTransferFee: number;
  tokenAddress: string;
  timestamp: string;
};

type NetworkDailyFeeSubgraphResponse = {
  cumulativeLpFee: string;
  cumulativeGasFee: string;
  cumulativeTransferFee: string;
  tokenAddress: string;
  timestamp: string;
};

function getTokenAddressTimestampPairs(networkDailyFeeData: NetworkDailyFee[]) {
  if (!networkDailyFeeData) return undefined;
  const uniqueTokenAddressTimestamps: { [tokenAddress: string]: string[] } = {};

  for (const networkDailyFee of networkDailyFeeData) {
    uniqueTokenAddressTimestamps[networkDailyFee.tokenAddress] = [
      ...(uniqueTokenAddressTimestamps[networkDailyFee.tokenAddress] || []),
      networkDailyFee.timestamp,
    ];
  }

  const tokenAddressTimestampPairs: [
    tokenAddress: string,
    timestamp: string
  ][] = [];

  for (const [tokenAddress, timestamps] of Object.entries(
    uniqueTokenAddressTimestamps
  )) {
    for (const timestamp of timestamps) {
      tokenAddressTimestampPairs.push([tokenAddress, timestamp]);
    }
  }

  return tokenAddressTimestampPairs;
}

function getUniqueTokenAddresses(networkDailyFeeData: NetworkDailyFee[]) {
  if (!networkDailyFeeData) return undefined;

  const uniqueTokenAddresses = new Set<string>();

  for (const networkDailyVolume of networkDailyFeeData) {
    uniqueTokenAddresses.add(networkDailyVolume.tokenAddress);
  }

  return Array.from(uniqueTokenAddresses);
}

async function getNetworkDailyFeeData(
  networkConfig: INetworkConfig,
  tokens: Token[]
) {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const epochModSecondsInADay = currentTimestamp % 86400;
  const todayEpoch = currentTimestamp - epochModSecondsInADay;
  const beginningEpoch = todayEpoch - 86400 * 10;

  const subgraphResponse = await request(
    networkConfig.subgraphEndpoint,
    gql`
      {
        dailyAssetSentPerFromChainAndTokens(
          orderBy: timestamp
          orderDirection: desc
          where: { 
            timestamp_gt: ${beginningEpoch}
          }
        ) {
          cumulativeLpFee
          cumulativeGasFee
          cumulativeTransferFee
          tokenAddress
          timestamp
        }
      }
    `
  );

  const deduplicatedNetworkDailyFees = (
    subgraphResponse.dailyAssetSentPerFromChainAndTokens as Array<NetworkDailyFeeSubgraphResponse>
  ).reduce((acc, curr) => {
    const key = `${curr.tokenAddress}-${curr.timestamp}`;
    const decimals = getNetworkTokens(networkConfig.networkId, tokens).find(
      (token) =>
        token[networkConfig.networkId].address.toLowerCase() ===
        curr.tokenAddress.toLowerCase()
    )![networkConfig.networkId].decimal;

    const typedCurrent: NetworkDailyFee = {
      ...curr,
      cumulativeLpFee: parseFloat(
        parseFloat(
          ethers.utils.formatUnits(curr.cumulativeLpFee, decimals)
        ).toFixed(3)
      ),
      cumulativeGasFee: parseFloat(
        parseFloat(
          ethers.utils.formatUnits(curr.cumulativeGasFee, decimals)
        ).toFixed(3)
      ),
      cumulativeTransferFee: parseFloat(
        parseFloat(
          ethers.utils.formatUnits(curr.cumulativeTransferFee, decimals)
        ).toFixed(3)
      ),
    };

    if (acc[key]) {
      acc[key].cumulativeLpFee += typedCurrent.cumulativeLpFee;
      acc[key].cumulativeGasFee += typedCurrent.cumulativeGasFee;
      acc[key].cumulativeTransferFee += typedCurrent.cumulativeTransferFee;
    } else {
      acc[key] = typedCurrent;
    }
    return acc;
  }, {} as { [key: string]: NetworkDailyFee });

  const networkDailyFees = Object.values(deduplicatedNetworkDailyFees);

  return networkDailyFees;
}

async function getTokenFiatPrices(
  tokenIdToContractAddressMapping:
    | ({
        [tokenAddress: string]: string;
      } & {
        [tokenId: string]: string;
      })
    | null,
  tokenAddress: string,
  timestamp: string
) {
  if (!tokenIdToContractAddressMapping) return undefined;

  let date = new Date(0);
  date.setUTCSeconds(parseInt(timestamp));
  var dd = String(date.getDate()).padStart(2, '0');
  var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = date.getFullYear();
  let dateString = `${dd}-${mm}-${yyyy}`;
  const tokenId = tokenIdToContractAddressMapping[tokenAddress];

  const rawRate = await axios.get(
    `https://pro-api.coingecko.com/api/v3/coins/${tokenId}/history`,
    {
      params: {
        date: dateString,
        localization: 'false',
        x_cg_pro_api_key: process.env.REACT_APP_COINGECKO_ID,
      },
    }
  );
  let priceInUSD: CoinGeckoHistoricalPrice = rawRate.data;
  // console.log(priceInUSD.market_data.current_price.usd);
  return {
    fiatPrice: priceInUSD.market_data.current_price.usd,
    tokenAddress,
    timestamp,
  };
}

async function getNetworkDailyLPFee(network: Network, tokens: Token[]) {
  const networkConfig = constructNetworkConfig(network, tokens);
  const networkDailyFeeData = await getNetworkDailyFeeData(
    networkConfig,
    tokens
  );

  if (!networkDailyFeeData) {
    return undefined;
  }

  const tokenFiatPriceTimestampMap: {
    [tokenAddress: string]: {
      [timestamp: string]: string;
    };
  } = {};

  const tokenAddressTimestampPairs =
    getTokenAddressTimestampPairs(networkDailyFeeData);

  const uniqueTokenAddresses = getUniqueTokenAddresses(networkDailyFeeData);

  const tokenIdToContractAddressMapping = uniqueTokenAddresses
    ? getTokenIdToContractAddressMapping(
        uniqueTokenAddresses,
        networkConfig,
        tokens
      )
    : null;

  let fiatPricesData: (
    | {
        fiatPrice: string;
        tokenAddress: string;
        timestamp: string;
      }
    | undefined
  )[] = [];

  if (tokenAddressTimestampPairs) {
    fiatPricesData = await Promise.all(
      tokenAddressTimestampPairs?.map(async (tokenAddressTimestampPair) => {
        const [tokenAddress, timestamp] = tokenAddressTimestampPair;
        return await getTokenFiatPrices(
          tokenIdToContractAddressMapping,
          tokenAddress,
          timestamp
        );
      })
    );
  }

  for (const fiatPricesQueryDatum of fiatPricesData) {
    const { fiatPrice, tokenAddress, timestamp } = fiatPricesQueryDatum!;
    if (!tokenFiatPriceTimestampMap[tokenAddress]) {
      tokenFiatPriceTimestampMap[tokenAddress] = {};
    }

    tokenFiatPriceTimestampMap[tokenAddress] = {
      ...tokenFiatPriceTimestampMap[tokenAddress],
      [timestamp]: fiatPrice,
    };
  }

  let networkDailyFiatFees: NetworkDailyFiatFee[] = [];
  for (const networkDailyFee of networkDailyFeeData) {
    networkDailyFiatFees.push({
      ...networkDailyFee,
      cumulativeFiatGasFee:
        networkDailyFee.cumulativeGasFee *
        parseFloat(
          tokenFiatPriceTimestampMap[networkDailyFee.tokenAddress][
            networkDailyFee.timestamp
          ]
        ),
      cumulativeFiatLpFee:
        networkDailyFee.cumulativeLpFee *
        parseFloat(
          tokenFiatPriceTimestampMap[networkDailyFee.tokenAddress][
            networkDailyFee.timestamp
          ]
        ),
      cumulativeFiatTransferFee:
        networkDailyFee.cumulativeTransferFee *
        parseFloat(
          tokenFiatPriceTimestampMap[networkDailyFee.tokenAddress][
            networkDailyFee.timestamp
          ]
        ),
    });
  }

  return networkDailyFiatFees;
}

const OverallDailyLpFeeGraph: React.FunctionComponent<
  IOverallDailyLPFeeGraphProps
> = ({ networks, tokens }) => {
  const networkDailyLPFeeQueries = useQueries(
    networks?.map((network) => {
      return {
        queryKey: [`dailyLPFeeOn${network.name}`, network.chainId],
        queryFn: () => getNetworkDailyLPFee(network, tokens),
      };
    }) ?? []
  );

  const isLoading = networkDailyLPFeeQueries.some(
    (networkDailyLPFeeQuery) => networkDailyLPFeeQuery.status === 'loading'
  );

  const graphData:
    | {
        [key: string]: {
          [key: string]: any;
        };
      }
    | undefined = !isLoading
    ? networkDailyLPFeeQueries.reduce(
        (data, networkDailyLPFeeQuery, currentIndex) => {
          const networkDailyLPFeeData = networkDailyLPFeeQuery.data;

          for (const dailyLPFeeData of networkDailyLPFeeData as NetworkDailyFiatFee[]) {
            const key = dailyLPFeeData.timestamp;
            const network = networks[currentIndex].name;
            let currentValue = data[key];
            if (currentValue && data[key][network]) {
              data[key][network] += dailyLPFeeData.cumulativeFiatLpFee;
            } else {
              data[key] = {
                ...data[key],
                timestamp: dailyLPFeeData.timestamp,
                [network]: dailyLPFeeData.cumulativeFiatLpFee,
              };
            }
          }

          return data;
        },
        {} as {
          [key: string]: {
            [key: string]: any;
          };
        }
      )
    : undefined;

  const processedGraphData = graphData
    ? (Object.values(graphData) as any)
    : undefined;

  return (
    <div className="h-[400px] py-6 px-4 border border-black/20 rounded-md flex flex-col">
      <div className="text-lg">Daily LP Fee (USD)</div>{' '}
      {!processedGraphData || processedGraphData.length === 0 ? (
        '...'
      ) : (
        <ResponsiveBar
          data={processedGraphData.map((d: any) => ({
            ...d,
            timestamp: format(
              new Date(parseInt(d.timestamp as string) * 1000),
              'dd/MM'
            ),
          }))}
          keys={networks.map((network) => network.name)}
          indexBy="timestamp"
          margin={{ top: 50, right: 150, bottom: 50, left: 60 }}
          padding={0.3}
          groupMode="stacked"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'nivo' }}
          valueFormat={(n) =>
            Intl.NumberFormat('en-US', {
              notation: 'compact',
              maximumFractionDigits: 2,
            }).format(n)
          }
          borderColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Token',
            legendPosition: 'middle',
            legendOffset: 40,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Lp Fee',
            legendPosition: 'middle',
            legendOffset: -40,
            format: (n) =>
              Intl.NumberFormat('en-US', {
                notation: 'compact',
                maximumFractionDigits: 2,
              }).format(n),
          }}
          labelSkipWidth={36}
          labelSkipHeight={12}
          labelTextColor={{
            from: 'color',
            modifiers: [['darker', 1.6]],
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="LP Fee Chart"
        />
      )}
    </div>
  );
};

export default OverallDailyLpFeeGraph;
