import {
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from 'config/config';
import { useQuery } from 'react-query';

import axios from 'axios';
import { useMemo } from 'react';
import useTokens from '../useTokens';
import useNetworkTokensLiquidityQuery from './NetworkTokensLiquidityQuery';

type NetworkTokenFiatLiquidity = {
  tokenSymbol: string;
  tokenContractAddress: string;
  suppliedLiquidity: string;
  suppliedFiatLiquidity: number;
  availableLiquidity: string;
  availableFiatLiquidity: number;
};

export default function useNetworkTokensFiatLiquidityQuery(
  networkConfig: INetworkConfig
) {
  const liquidityQuery = useNetworkTokensLiquidityQuery(networkConfig);

  const { data: tokensData } = useTokens();

  const tokens = tokensData
    ? Object.keys(tokensData)
        .map((tokenSymbol: string) => tokensData[tokenSymbol])
        .filter((token) => token.symbol !== 'AVAX')
    : [];

  const uniqueTokenAddresses = useMemo(() => {
    if (!liquidityQuery.data) return undefined;
    const uniqueTokenAddresses = new Set<string>();

    for (const liquidityDetails of liquidityQuery.data) {
      uniqueTokenAddresses.add(liquidityDetails.tokenContractAddress);
    }
    return Array.from(uniqueTokenAddresses);
  }, [liquidityQuery]);

  const tokenFiatPriceQuery = useQuery(
    ['tokensPriceCurrent', uniqueTokenAddresses],
    async () => {
      if (!uniqueTokenAddresses) return undefined;
      const tokenIdToContractAddressMapping =
        getTokenIdToContractAddressMapping(
          uniqueTokenAddresses,
          networkConfig,
          tokens
        );
      const rawRates = await axios.get(
        `https://pro-api.coingecko.com/api/v3/simple/price`,
        {
          params: {
            ids: Object.values(tokenIdToContractAddressMapping).join(','),
            vs_currencies: 'USD',
            x_cg_pro_api_key: process.env.REACT_APP_COINGECKO_ID,
          },
        }
      );

      const tokenRates: { [tokenAddress: string]: number } = {};
      const rates = rawRates.data as { [tokenId: string]: { usd: number } };
      for (const [tokenId, { usd }] of Object.entries(rates)) {
        tokenRates[tokenIdToContractAddressMapping[tokenId]] = parseFloat(
          usd as unknown as string
        );
      }

      return tokenRates;
    },
    {
      enabled: !!uniqueTokenAddresses,
      staleTime: 3600000, // 1 hour
    }
  );

  return useQuery<undefined | NetworkTokenFiatLiquidity[]>(
    ['fiatLiquidity', networkConfig.networkName],
    async () => {
      if (!liquidityQuery.data || !tokenFiatPriceQuery.data) {
        return undefined;
      }

      const tokenRates = tokenFiatPriceQuery.data;

      const networkTokenFiatLiquidities: NetworkTokenFiatLiquidity[] =
        liquidityQuery.data.map((networkTokenLiquidity) => ({
          ...networkTokenLiquidity,
          availableFiatLiquidity:
            parseFloat(networkTokenLiquidity.availableLiquidity) *
            tokenRates[networkTokenLiquidity.tokenContractAddress],
          suppliedFiatLiquidity:
            parseFloat(networkTokenLiquidity.suppliedLiquidity) *
            tokenRates[networkTokenLiquidity.tokenContractAddress],
        }));
      return networkTokenFiatLiquidities;
    },
    {
      staleTime: 60000,
      enabled: !!liquidityQuery.data && !!tokenFiatPriceQuery.data,
    }
  );
}
