import { Network } from "hooks/query/useNetworks";
import { Token } from "hooks/query/useTokens";
import useNetworkDayFiatVolumeQuery from "hooks/query/volume/NetworkDayFiatVolumeQuery";
import { NetworkDayFiatVolume } from "hooks/query/volume/NetworkDayVolumeQuery";
import React, { useMemo } from "react";
import { constructNetworkConfig } from "utils/constructNetworkConfig";
import NetworkDataCard from "./NetworkDataCard";

const NetworkDayVolumeCard: React.FC<{
  network: Network;
  tokens: Token[];
}> = ({ network, tokens }) => {
  const networkConfig = constructNetworkConfig(network, tokens);
  const dayVolumeQuery = useNetworkDayFiatVolumeQuery(networkConfig);

  const dayVolume = useMemo(() => {
    if (!dayVolumeQuery.data) return undefined;

    const dayVolumeFloat = (
      dayVolumeQuery.data as NetworkDayFiatVolume[]
    ).reduce((acc, curr) => {
      return acc + curr.cumulativeFiatAmount || 0;
    }, 0);

    return "$ " + parseInt(dayVolumeFloat.toString()).toLocaleString("en-US");
  }, [dayVolumeQuery.data]);

  return (
    <NetworkDataCard
      body={dayVolume || "..."}
      network={network}
      title="24 Hour Volume"
    />
  );
};

export default NetworkDayVolumeCard;
