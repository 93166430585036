import axios from 'axios';
import {
  getTokenIdToContractAddressMapping,
  INetworkConfig,
} from 'config/config';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import useTokens from '../useTokens';
import useNetworkTotalVolumeQuery, {
  NetworkTotalVolume,
} from './NetworkTotalVolumeQuery';

export type NetworkTotalFiatVolume = NetworkTotalVolume & {
  cumulativeFiatAmount: number;
};

export default function useNetworkTotalFiatVolumeQuery(
  networkConfig: INetworkConfig
) {
  const networkTotalVolumeQuery = useNetworkTotalVolumeQuery(networkConfig);

  const { data: tokensData } = useTokens();

  const tokens = tokensData
    ? Object.keys(tokensData)
        .map((tokenSymbol: string) => tokensData[tokenSymbol])
        .filter((token) => token.symbol !== 'AVAX')
    : [];

  const networkTotalVolumes = networkTotalVolumeQuery.data;

  const uniqueTokenAddresses = useMemo(() => {
    if (!networkTotalVolumes) return undefined;
    const uniqueTokenAddresses = new Set<string>();

    for (const networkTotalVolume of networkTotalVolumes) {
      uniqueTokenAddresses.add(networkTotalVolume.tokenAddress);
    }

    return Array.from(uniqueTokenAddresses);
  }, [networkTotalVolumes]);

  const tokenFiatPriceQuery = useQuery(
    ['tokensPriceCurrent', uniqueTokenAddresses],
    async () => {
      if (!uniqueTokenAddresses) return undefined;
      const tokenIdToContractAddressMapping =
        getTokenIdToContractAddressMapping(
          uniqueTokenAddresses,
          networkConfig,
          tokens
        );
      const rawRates = await axios.get(
        `https://pro-api.coingecko.com/api/v3/simple/price`,
        {
          params: {
            ids: Object.values(tokenIdToContractAddressMapping).join(','),
            vs_currencies: 'USD',
            x_cg_pro_api_key: process.env.REACT_APP_COINGECKO_ID,
          },
        }
      );

      const tokenRates: { [tokenAddress: string]: number } = {};
      const rates = rawRates.data as { [tokenId: string]: { usd: number } };
      for (const [tokenId, { usd }] of Object.entries(rates)) {
        tokenRates[tokenIdToContractAddressMapping[tokenId]] = parseFloat(
          usd as unknown as string
        );
      }

      return tokenRates;
    },
    {
      enabled: !!uniqueTokenAddresses,
      staleTime: 3600000, // 1 hour
    }
  );

  return useQuery<undefined | NetworkTotalFiatVolume[]>(
    ['cumulativeFiatVolume', networkConfig.networkName],
    async () => {
      if (!networkTotalVolumes || !tokenFiatPriceQuery.data) {
        return undefined;
      }

      const tokenRates = tokenFiatPriceQuery.data;

      const networkTotalFiatVolumes = networkTotalVolumes.map(
        (networkTotalVolume) => ({
          ...networkTotalVolume,
          cumulativeFiatAmount:
            networkTotalVolume.cumulativeAmount *
            tokenRates[networkTotalVolume.tokenAddress],
        })
      );
      return networkTotalFiatVolumes as NetworkTotalFiatVolume[];
    },
    {
      staleTime: 60000,
      enabled: !!networkTotalVolumes && !!tokenFiatPriceQuery.data,
    }
  );
}
